import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import debounce from "debounce";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { imageUrlApi, WEBSITE } from "@/config";
import { GET_TRANSFERS } from "@/graphql/admin/payment";
import Swal from "sweetalert2";
export default {
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "โอนเงินไปจีน"
    }]);
  },
  created: function created() {
    document.title = "\u0E42\u0E2D\u0E19\u0E40\u0E07\u0E34\u0E19\u0E44\u0E1B\u0E08\u0E35\u0E19 | ".concat(WEBSITE);
  },
  data: function data() {
    return {
      page: 1,
      pageSize: 10,
      orderList: {},
      loading: false,
      status: "",
      search: ""
    };
  },
  methods: {
    imageUrlApi: imageUrlApi,
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    getStatusColor: function getStatusColor(status) {
      switch (status) {
        case -1:
          return "default";

        case 0:
          return "warning";

        case 1:
          return "success";

        default:
          return "primary";
      }
    },
    getStatusText: function getStatusText() {
      switch (this.status) {
        case "":
          return "ทั้งหมด";

        case "0":
          return "รอผู้ดูแลตรวจสอบ";

        case "1":
          return "สำเร็จ";

        case "-1":
          return "ยกเลิกแล้ว";

        default:
          return "";
      }
    },
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var yuan = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var currency = format ? yuan ? "¥ " : "฿ " : "";

      if (format) {
        if (val >= 0) return "".concat(currency).concat(Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
        return "-".concat(currency).concat(Number(bigDecimal.round(Math.abs(val), decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
      }

      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    systemPaymentList: {
      query: GET_TRANSFERS,
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          status: this.status
        };
      },
      result: function result(_ref) {
        var data = _ref.data,
            loading = _ref.loading;

        if (loading || !data) {
          return;
        }

        var systemPaymentList = data.systemPaymentList;
        var isSuccess = systemPaymentList.isSuccess,
            message = systemPaymentList.message;

        if (!isSuccess) {
          Swal.fire({
            title: "",
            text: message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        }
      }
    }
  }
};